import song1 from "../songs/smells-like-teen-spirit.txt?raw";
import song2 from "../songs/judy-is-a-punk.txt?raw";
import song3 from "../songs/hatebreeders.txt?raw";
import song4 from "../songs/gods-plan.txt?raw";
import song5 from "../songs/perfect.txt?raw";
import song6 from "../songs/finesse-remix.txt?raw";
import song7 from "../songs/son-of-a-gun.txt?raw";
import song8 from "../songs/sparks.txt?raw";
import song9 from "../songs/black-hole-sun.txt?raw";

export default [
    song1,
    song2,
    song3,
    song4,
    song5,
    song6,
    song7,
    song8,
    song9
];
